<template>
  <v-app>
    <v-container
      class="my-6 mx-auto scoreTables"
      style="max-width: 100% !important"
    >
      <span id="autotime"></span>
      <v-card>
        <div
          class="card-header-padding"
          style="padding-top: 2rem; padding-bottom: 0rem"
        >
          <v-row>
            <v-col cols="3">
              <v-select
                return-object
                v-model="currenSelectedLesson"
                :items="lessons"
                item-text="name2"
                item-value="id"
                label="Xичээл сонгоx"
              >
              </v-select>
            </v-col>

            <v-col cols="3">
              <v-select
                v-if="currenSelectedLesson"
                return-object
                v-model="currenSelectedClassGroup"
                :items="currenSelectedLesson.classGroups"
                item-text="name2"
                item-value="classGroupRef"
                label="Анги/Бүлэг сонгоx"
              >
              </v-select>
            </v-col>

            <v-col>
              <!-- <v-select
                return-object
                v-model="selectedMonth"
                :items="months"
                item-text="name"
                item-value="id"
                label="Сар сонгоx"
              >
              </v-select> -->

              <v-row justify="end">
                <small
                  @click="selectedMonth = month"
                  small
                  style="
                    cursor: pointer;
                    background: #dadada;
                    color: #000 !important;
                  "
                  class="
                    border-radius-sm
                    text-xxs
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                  "
                  :class="
                    selectedMonth == month
                      ? 'bg-gradient-warning white--text'
                      : ''
                  "
                  :style="selectedMonth == month ? 'color: white' : ''"
                  elevation="0"
                  :ripple="false"
                  v-for="(month, mindex) in months"
                  :key="'month-' + mindex"
                  >{{ month }}</small
                >
              </v-row>
            </v-col>
          </v-row>
        </div>
        <template
          v-if="
            currenSelectedLesson && currenSelectedClassGroup && selectedMonth
          "
        >
          <v-card v-if="loading" height="200">
            <v-progress-linear indeterminate color="red"></v-progress-linear>
          </v-card>
          <h1>kjdjfksdjfjsdf</h1>
        </template>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    selectedYear: null,
    selectedDay: null,
    hutolbors: null,
    loading: false,
    currenSelectedClassGroup: null,
    months: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12],
    currenSelectedLesson: null,
    tabClassGroups: null,
    selectedMonth: null,
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    students: null,
    lessons: null,
  }),
  props: {
    lessonObject: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },

    headers2() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < 31; i++) {
        list.push({
          text: i + 1,
          align: "center",
          value: "val-" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
    body2() {
      var list = [];
      var row;
      for (var k = 0; k < 300; k++) {
        row = {
          index: k + 1,
          firstName: "User-" + (k + 1),
        };
        for (var i = 1; i < 31; i++) {
          var xx = "val-" + (i + 1).toString();
          var tmp = {};
          tmp[xx] = i + 1;

          console.log(tmp);
          row[xx] = i + 1;
        }
        list.push(row);
      }

      return list;
    },
  },
  watch: {
    currenSelectedClassGroup(val) {
      var x = val.classGroupRef;
      if (this.students && this.students[x.path] == undefined) {
        this.findStudents(x);
      }
    },
    selectedMonth() {},
    currenSelectedLesson(val) {
      console.log(val);
      this.currenSelectedLesson = val;
      if (this.currenSelectedLesson.classGroups) {
        this.currenSelectedLesson.classGroups.forEach((doc) => {
          doc.name2 = doc.departmentName + doc.classGroupName;
        });
      }
      if (this.currenSelectedLesson.topics == null) {
        this.currenSelectedLesson.ref
          .collection("topics")
          .onSnapshot((docs) => {
            this.currenSelectedLesson.topics = [];
            docs.forEach((doc) => {
              var topic = doc.data();
              topic.id = doc.id;
              topic.ref = doc.ref;
              console.log(topic);
              this.currenSelectedLesson.topics.push(topic);
            });
          });
      }
      this.students = [];
      this.tabClassGroups = 0;
    },
  },
  methods: {
    findStudents(classGroupRef) {
      this.loading = true;
      fb.db
        .collection(this.zzschool + "/students")
        .where(
          "classGroup-" + this.userData.school.currentYear,
          "==",
          classGroupRef
        )
        .get()
        .then((docs) => {
          this.students[classGroupRef.path] = [];
          docs.forEach((doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            student.classGroupRef = classGroupRef;
            student.attendances = null;
            student.ref
              .collection("attendances")
              .doc(this.currenSelectedLesson.id)
              .collection("attendance")
              .onSnapshot((docs) => {
                student.attendances = [];
                if (!docs.empty) {
                  docs.forEach((doc) => {
                    if (doc.exists) {
                      // console.log(doc.data()); alga bolgoldoo
                      let x = doc.data();
                      x.ref = doc.ref;
                      x.id = doc.id;
                      student.attendances.push(x);
                    }
                  });
                }
              });
            this.students[classGroupRef.path].push(student);
          });
          this.loading = false;
        });
    },
    startTime() {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      document.getElementById("autotime").innerHTML = h + ":" + m + ":" + s;
      setTimeout(this.startTime, 1000);
    },

    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },
    async _setupp() {
      var query = fb.db
        .collection(this.zzschool + "/lessons")
        .where("deleted", "==", false)
        .where("year", "==", this.userData.school.currentYear);

      if (this.userData.role == "teacher") {
        query = query.where("teacherRef", "==", this.userData.ref);
      }
      query = query.orderBy("createdAt", "desc");

      query.onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.topics = null;
          lesson.name2 =
            lesson.categoryName +
            " - " +
            (lesson.lessonType == 1 ? "Заавал" : "Сонгон");
          if (this.currenSelectedLesson == null && this.lessonObject == null) {
            this.currenSelectedLesson = lesson;
            if (lesson.classGroups && lesson.classGroups.length > 0) {
              this.currenSelectedClassGroup = lesson.classGroups[0];
              this.findStudents(this.currenSelectedClassGroup.classGroupRef);
            }
          }

          this.lessons.push(lesson);
        });
      });
      this.hutolbors = [
        { name: "X1", id: "x1" },
        { name: "X2", id: "x2" },
      ]; //TODO
    },
  },
  created() {
    if (this.lessonObject != null) {
      this.currenSelectedLesson = this.lessonObject;
      if (this.currenSelectedLesson.classGroups) {
        this.currenSelectedLesson.classGroups.forEach((doc) => {
          doc.name2 = doc.departmentName + doc.classGroupName;
        });
      }
    }

    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedDay = new Date().getDate();
    this.selectedYear = new Date().getFullYear();
    this._setupp();
    this.headerNames = [
      {
        text: "xxx",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ];
    for (var x of Array(12).keys()) {
      x++;
      this.headerNames.push({
        text: x,
        align: "start",
        sortable: true,
        value: x,
      });
    }
    this.bodyNames = ["firstName"];
  },
  mounted() {},
};
</script>
 <style>
</style>

 